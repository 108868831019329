import { right } from "fp-ts/lib/Either";
import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";
import callAPI from "../../../fetchCallImplementation";

//TODO implement
const CreateAIMomentByIdApiImpl = async (customer: string, lob: string, type: string, description: string, weight: number): Promise<any> => {

    let body = {
        customer,
        lob,
        type,
        description,
        weight
    }

    const baseUrl = di.get<Constants>(ConstantsName).AI;
    let response = await callAPI(baseUrl + `/keywords`, 'POST', body);
    
    if (!response.ok) {
        let data: any = await response.json();
        return right({error: data.message});
    }
    
    let data = await response.json();
    return right({message: data.message});
    
}

export default CreateAIMomentByIdApiImpl;
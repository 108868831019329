//import { Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useCollection } from '@cloudscape-design/collection-hooks';
import { Table, TextFilter, Pagination, CollectionPreferences, PropertyFilter, Header } from '@cloudscape-design/components';
import Popover from "@cloudscape-design/components/popover";
import Button from "@cloudscape-design/components/button";
import StatusIndicator from "@cloudscape-design/components/status-indicator";
import Icon from "@cloudscape-design/components/icon";
import Link from "@cloudscape-design/components/link";
import { ContactTablePreferences } from "./ContactTablePreferences";
import { DateTimeForm, formatDateTime } from './components/DateTimeForm';

import "./ContactTable.css";
import { useNavigate } from "react-router-dom";
import { SentimentIcon } from "./components/SentimentIcon";
import { TrendIcon } from "./components/TrendIcon";
import { Formatter } from "./components/format";
import Icons from "../../assets/Icons";

interface Token {
  propertyKey: string;
  operator: string;
  value: string;
}

interface FilterGroup {
  tokens: Token[];
  operation: string;
}


const COLUMN_DEFINITIONS = [
  {
    id: "timestamp",
    header: "Timestamp",
    cell: (d: any) => {
      //console.log('Selected Timestamp:', d?.timestamp);
      return (
        <div style={{width: '100%', textAlign: 'center'}}>
          <Link href={`/aisummary/${d?.key.split("parsedFiles/")[1].split(".")[0]}/${d?.key.split("parsedFiles/")[1].split(".")[1]}`}>{(d?.timestamp ?? 0)}</Link>
        </div>
      )      
    },
    minWidth: 160,
    sortingField:'timestamp'
  },
  {
    id: "jobName",
    header: "Job Name",
    cell: (d: any) => {
      return (
        <Link href={`/aisummary/${d?.key.split("parsedFiles/")[1].split(".")[0]}/${d?.key.split("parsedFiles/")[1].split(".")[1]}`}>{d?.jobName}</Link>
      )      
    },
    isRowHeader: true,
    sortingField:'jobName'
  },
  {
    id: "guid",
    header: "GUID",
    cell: (d: any) => d?.guid,
    isRowHeader: true,
    minWidth:150
  },
  {
    id: "agent",
    header: <>Agent</>,
    cell: (d: any) => d?.agent,
    isRowHeader: true,
    minWidth:150,
    sortingField:'agent'
  },
  {
    id: "customer",
    header: "Customer",
    cell: (d: any) => d?.customer,
    isRowHeader: true,
    minWidth:150
  },
  {
    id: "LOB",
    header: "LOB",
    cell: (d: any) => {
      return d?.LOB
    },
    isRowHeader: true,
    minWidth:150,
    sortingField: 'JOB'
  },
  {
    id: "summary_summary",
    header: "Summary",
    cell: (d: any) => {
      if (d?.summary_summary !== undefined) {
        return (
          <Popover
            dismissButton={false}
            position="top"
            size="large"
            triggerType="text"
            content={d?.summary_summary}
          >
            {(d?.summary_summary.length > 20 ? d?.summary_summary.substring(0, 20) + "..." : d?.summary_summary)}
          </Popover>
          /*
          <ExpandableSection headerText={(d?.summary_summary.length > 50 ? d?.summary_summary.substring(0,50) + "..." : d?.summary_summary )}>
            {d?.summary_summary}
          </ExpandableSection>*/
        )
      }
      return 'n/a';
    },
    isRowHeader: true,
    minWidth:200
  },
  {
    id: "callerSentimentScore",
    header: "Cust Sent",
    cell: (d: any) => (
      <div className="d-flex justify-content-evenly">
          <SentimentIcon score={d?.callerSentimentScore} size="30px" />
          <TrendIcon trend={d?.callerSentimentChange} />
      </div>
    ),
    minWidth: 130
  },
  {
    id: "langCode",
    header: <div className="col-header-wrapper text-left">Lang Code</div>,
    cell: (d: any) => d?.lang,
    minWidth: 130,
  },
  {
    id: "duration",
    header: <div className="col-header-wrapper text-left">Duration</div>,
    cell: (d: any) => d?.duration,
    minWidth: 130,
    sortingField: 'duration'
  },
  {
    id: "manualEvaluated",
    header: <div className="col-header-wrapper text-left">Manual Evaluated</div>,
    cell: (d: any) => {
      return(
        <div style={{display: 'flex', justifyContent: 'center'}}>
        {
          d?.manualEvaluated === 'True' ?
          
              <Icons.CheckRounded color='green' />:
              <div style={{border: '2px solid red', borderRadius: '50%', width: 'fit-content', height: 'fit-content', marginLeft: '5px'}}>
                  <Icons.Clear color='red' />
              </div>
        }
        </div>
        
      )
      },
    minWidth: 130,
  },
  {
    id: "manualOverallScore",
    header: <div className="col-header-wrapper text-left">Manual Score</div>,
    cell: (d: any) => {
      return(
        <div style={{display: 'flex', justifyContent: 'center'}}>
        {
          d?.manualOverallScore !== null && d?.manualOverallScore !== undefined ?
          <>
            {(Math.floor(d?.manualOverallScore * 100) / 100).toFixed(2)}%
          </>:
          <>
            No Data
          </>
        }
        </div>
      )
      },
    minWidth: 130,
    sortingField: 'manualOverallScore'
  },
  {
    id: "aiEvaluated",
    header: <div className="col-header-wrapper text-left">AI Evaluated</div>,
    cell: (d: any) => {
      return(
        <div style={{display: 'flex', justifyContent: 'center'}}>
        {
          d?.aiEvaluated === 'True' ?
          
              <Icons.CheckRounded color='green' />:
              <div style={{border: '2px solid red', borderRadius: '50%', width: 'fit-content', height: 'fit-content', marginLeft: '0px'}}>
                  <Icons.Clear color='red' />
              </div>
        }
        </div>
      )
      },
      minWidth: 130,
    },
    {
      id: "aiOverallScore",
      header: 'Ai Score',
      cell: (d: any) => {
        return(
          <div style={{marginLeft: '10px'}}>
          {
            d?.aiOverallScore !== null ?
            <div>
              {(Math.floor(d?.aiOverallScore * 100) / 100).toFixed(2)}%
            </div> : 
            <>
              No Data
            </>
          }
          </div>
        )
        },
        sortingField: 'aiOverallScore',
        minWidth: 130,
    },
];

const getMatchesCountText = function getMatchesCountText(count: any) {
  return count === 1 ? `1 match` : `${count} matches`;
}


type ContactTableProps = {
    data: Array<any>;
    loading?: boolean;
    header?: React.ReactNode; // Adjust this type based on what `header` is supposed to be
    variant?: any; // Adjust the variants accordingly
  };



export const ContactTable: React.FC<ContactTableProps> = ({ data = [], loading = false, header, variant='container' }) => {
  
  data = data.map(item => {
    
    return {
      ...item,
      timestamp: new Date(item?.timestamp).toISOString(),
      duration: Formatter.Time(item.duration),
      sentimentState: item.callerSentimentScore < 0 ? 'Negative' : 'Positive',
      aiOverallScore: item.aiOverallScore !== null && item.aiOverallScore !== undefined ? (Math.floor(item?.aiOverallScore * 1) / 1) :  null,
      manualOverallScore: item.manualOverallScore !== null && item.manualOverallScore !== undefined  ? (Math.floor(item?.manualOverallScore * 1000) / 1000) :  null,
      LOB: item.LOB ? item.LOB : '',
      sorted: {
        field: '',
        des: false
      }
    }
  });
  const oldData = data;
  data = ([ data[0], ...data]);
  data = data[0] !== undefined ? data : [];
  

  const [ filterOptions, setFilterOptions ] = useState([]);
  const [ dataToShow, setDataToShow ] = useState<any[]>(data);
  const [ sortedInfo, setSortedInfo ] = useState<any>(
    {
      field: '',
      des: false
    }
  );

  const preferences: any = {
      "pageSize": 30,
      "wrapLines": false,
      "stripedRows": false,
      "contentDensity": "comfortable",
      "visibleContent": [
          "timestamp",
          "jobName",
          "agent",
          "LOB",
          "callerSentimentScore",
          "langCode",
          "duration",
          "manualEvaluated",
          "manualOverallScore",
          "aiEvaluated",
          "aiOverallScore"
      ]
  }


  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(
    data,
    {
      propertyFiltering: {
        filteringProperties: [
          {
            key: "timestamp",
            defaultOperator: '>',
            operators: ['<', '<=', '>', '>='].map(operator => ({
              operator,
              form: DateTimeForm,
              format: formatDateTime,
              match: 'datetime',
            })),
            propertyLabel: "Timestamp",
            groupValuesLabel: "Timestamps"
          },
          {
            key: "jobName",
            operators: ["=", "!="],
            propertyLabel: "Job Name",
            groupValuesLabel: "Job Names"
          },
          {
            key: "agent",
            operators: ["=", "!="],
            propertyLabel: "Agent",
            groupValuesLabel: "Agents"
          },
          {
            key: "sentimentState",
            operators: ["=", "!="],
            propertyLabel: "Caller Sentiment",
            groupValuesLabel: "Caller Sentiment"
          },
          {
            key: "customer",
            operators: ["=", "!="],
            propertyLabel: "Customer",
            groupValuesLabel: "Customers"
          },
          {
            key: "lang",
            operators: ["=", "!="],
            propertyLabel: "Language Code",
            groupValuesLabel: "Languages Codes"
          },
          {
            key: "duration",
            operators: ['<', '<=', '>', '>='],
            defaultOperator: '>',
            propertyLabel: "Duration",
            groupValuesLabel: "Durations"
          },
          {
            key: "manualEvaluated",
            operators: ['=', '!='],
            propertyLabel: "Manual Evaluated",
            groupValuesLabel: "Manual Evaluated"
          },
          {
            key: "manualOverallScore",
            operators: ['<', '<=', '>', '>='],
            defaultOperator: '>',
            propertyLabel: "Manual Overall Score",
            groupValuesLabel: "Manual Overall Score"
          },
          {
            key: "aiEvaluated",
            operators: ['=', '!='],
            propertyLabel: "Ai Evaluated",
            groupValuesLabel: "Ai Evaluated"
          },
          {
            key: "aiOverallScore",
            operators: ['<', '<=', '>', '>='],
            defaultOperator: '>',
            propertyLabel: "Ai Overall Score",
            groupValuesLabel: "Ai Overall Score"
          },
        ],
        empty: (
          <div>No Calls.</div>
        ),
        noMatch: (
          <div>No matches.</div>
        )
      },
      pagination: { pageSize: 100000000 },
      sorting: {},
      selection: {},
    });
  
  interface Token {
    propertyKey: string;
    operator: string;
    value: any;
  }
  
  interface FilterGroup {
    tokens: Token[];
    logic: "AND" | "OR"; // Logic within this group
  }
  const elementFix = document.querySelector(`[data-focus-id="${'header-aiOverallScore'}"] > div > span > span > svg`) as HTMLElement;
  if (elementFix) {
    elementFix.style.marginLeft = "-100px";
  }
  const applyFilter = (data: any[], filters: FilterGroup[], logic: "AND" | "OR"): any[] => {

    let dataStore = data;
    
    filters.map(filterGroup => {
        // Check token logic within a group
        filterGroup.tokens.forEach(({ propertyKey, operator, value }) => {
          if (operator === '=') {
            let newData = dataStore.filter(item => item[propertyKey] === value);
            dataStore = newData;
          } else if (operator === '!=') {
            let newData = dataStore.filter(item => item[propertyKey] !== value);
            dataStore = newData;
          } else if (operator === '>=') {
            if (propertyKey !== 'timestamp' && propertyKey !== 'duration') {
              let newData = dataStore.filter(item => parseFloat(item[propertyKey]) >= parseFloat(value));
              dataStore = newData;
            } else if (propertyKey === 'timestamp') {
              let newData = dataStore.filter(item => new Date(item[propertyKey]) >= new Date(value));
              dataStore = newData;
            } else if (propertyKey === 'duration'){
              let newData = dataStore.filter(item => Formatter.convertToNumber(item[propertyKey]) >= Formatter.convertToNumber(value));
              dataStore = newData;
            }
          } else if (operator === '<=') {
            if (propertyKey !== 'timestamp' && propertyKey !== 'duration') {
              let newData = dataStore.filter(item => parseFloat(item[propertyKey]) <= parseFloat(value));
              dataStore = newData;
            } else if (propertyKey === 'timestamp') {
              let newData = dataStore.filter(item => new Date(item[propertyKey]) <= new Date(value));
              dataStore = newData;
            } else if (propertyKey === 'duration'){
              let newData = dataStore.filter(item => Formatter.convertToNumber(item[propertyKey]) <= Formatter.convertToNumber(value));
              dataStore = newData;
            }
          } else if (operator === '<') {
            if (propertyKey !== 'timestamp' && propertyKey !== 'duration') {
              let newData = dataStore.filter(item => parseFloat(item[propertyKey]) < parseFloat(value));
              dataStore = newData;
            } else if (propertyKey === 'timestamp') {
              let newData = dataStore.filter(item => new Date(item[propertyKey]) < new Date(value));
              dataStore = newData;
            } else if (propertyKey === 'duration'){
              let newData = dataStore.filter(item => Formatter.convertToNumber(item[propertyKey]) < Formatter.convertToNumber(value));
              dataStore = newData;
            }
          } else if (operator === '>') {
            if (propertyKey !== 'timestamp' && propertyKey !== 'duration') {
              let newData = dataStore.filter(item => parseFloat(item[propertyKey]) > parseFloat(value));
              dataStore = newData;
            } else if (propertyKey === 'timestamp') {
              let newData = dataStore.filter(item => new Date(item[propertyKey]) > new Date(value));
              dataStore = newData;
            } else if (propertyKey === 'duration'){
              let newData = dataStore.filter(item => Formatter.convertToNumber(item[propertyKey]) > Formatter.convertToNumber(value));
              dataStore = newData;
            }
          }
        
      });
    });
    
    return dataStore;
  };
  
  
  const handleFilterChange = (e: any) => {
    
    let newToken = e.tokens[0].propertyKey + e.tokens[0].value;
    
    let keysToCheck = filterOptions.filter((item: any) => item.tokens[0].propertyKey + item.tokens[0].value === newToken);
    
    let newOptions: any = [];
    if (keysToCheck.length > 0) {
      newOptions = filterOptions.filter((item: any) => item.tokens[0].propertyKey + item.tokens[0].propertyKey !== newToken);
      setFilterOptions(newOptions);
      const filteredData = applyFilter(oldData, newOptions, "AND");
      data = ([ filteredData[0], ...filteredData]);
      setDataToShow([...data]);
    } else {
      newOptions = filterOptions;
      const updatedOptions: any = [...newOptions, e];
      
      setFilterOptions(updatedOptions);
      const filteredData = applyFilter(oldData, updatedOptions, "AND");
      data = ([ filteredData[0], ...filteredData]);
      setDataToShow([...data]);
    }


    
  }

  const _handleDeleteFilter = (value: any) => {
    const optionClean = filterOptions.filter((item: any) => item?.tokens[0]?.value !== value);
    const filteredData = applyFilter(oldData, optionClean, "AND");
    
    data = ([ filteredData[0], ...filteredData]);
    setDataToShow([...data]);
    setFilterOptions(optionClean);
  }



  function sortByField(array: any, field: any, isAscending = true) {
    return array.sort((a: any, b: any) => {
      const valueA = a[field];
      const valueB = b[field];

      if (field === 'timestamp') {
        if (new Date(valueA) < new Date(valueB)) return isAscending ? -1 : 1;
        if (new Date(valueA) > new Date(valueB)) return isAscending ? 1 : -1;
        return 0;
      }

      if (valueA < valueB) return isAscending ? -1 : 1;
      if (valueA > valueB) return isAscending ? 1 : -1;
      return 0;
    });
  }

  const _handleSortingChange = (e: any) => {
      // Select the parent element
  let child: any = {
    timestamp: 'header-timestamp',
    jobName: 'header-jobName',
    agent: 'header-agent',
    LOB: 'header-JOB',
    duration: 'header-duration',
    aiOverallScore: 'header-aiOverallScore',
    manualOverallScore: 'header-manualOverallScore'
  }

  Object.entries(child).forEach(([key, value]) => {
    const elementClean = document.querySelector(`[data-focus-id="${value}"] > div > span > span > svg`) as HTMLElement;
    if (elementClean) {
      elementClean.style.border = "1px solid white";
    }
  });
  
  const element = document.querySelector(`[data-focus-id="${child[e.detail.sortingColumn.id]}"] > div > span > span > svg`) as HTMLElement;
  
  if (element) {
      if (sortedInfo.des) {
        
      element.setAttribute('transform', 'rotate(360 0 0)'); 
      } else {
        
      element.setAttribute('transform', 'rotate(180 0 0)'); 
      }
      // Do something with the first child
  }
    let dataClean = dataToShow;
    dataClean.shift();
    
    let newData = dataClean.map((val: any) => {
      return(
        {
          ...val,
          sorted: {
            field: e.detail.sortingColumn.id,
            des: !val.sorted.des
          }
        }
      )
    });
    newData = sortByField(newData, e.detail.sortingColumn.id, !sortedInfo.des);
    data = ([ newData[0], ...newData]);
    
    setDataToShow(data);
    
    setSortedInfo({
      ...sortedInfo,
      field: e.detail.sortingColumn.id,
      des: !sortedInfo.des
    })
    
  }

  return (
    <Table
      {...collectionProps}
      header={
        <Header
        >
          Call List
        </Header>
      }
      
      variant={variant}
      columnDefinitions={COLUMN_DEFINITIONS}
      columnDisplay={preferences.contentDisplay}
      items={dataToShow}
      //pagination={<Pagination {...paginationProps} />}
      resizableColumns={true}
      loadingText="Loading Calls"
      // onSelectionChange={onClick}
      // onRowClick={onClick}
      // selectionType="single"
      stickyHeaderVerticalOffset={20}
      stickyColumns={{ first: 2, last: 0 }}
      stickyHeader={true}
      onSortingChange={(e) => _handleSortingChange(e)}
      filter={
        <div style={{margin: "20px"}}>
          
      <PropertyFilter
          {...propertyFilterProps}
          onChange={({ detail }) => handleFilterChange(detail)}
          /*onChange={({ detail }) => {
            console.log(detail);
            //setCallQuery(detail);
          }}*/
          //query={callQuery}
          i18nStrings={{
            filteringAriaLabel: "your choice",
            dismissAriaLabel: "Dismiss",
            filteringPlaceholder: "Find calls",
            groupValuesText: "Values",
            groupPropertiesText: "Properties",
            operatorsText: "Operators",
            operationAndText: "and",
            operationOrText: "or",
            operatorLessText: "Less than",
            operatorLessOrEqualText: "Less than or equal",
            operatorGreaterText: "Greater than",
            operatorGreaterOrEqualText:
              "Greater than or equal",
            operatorContainsText: "Contains",
            operatorDoesNotContainText: "Does not contain",
            operatorEqualsText: "Equals",
            operatorDoesNotEqualText: "Does not equal",
            editTokenHeader: "Edit filter",
            propertyText: "Property",
            operatorText: "Operator",
            valueText: "Value",
            cancelActionText: "Cancel",
            applyActionText: "Apply",
            allPropertiesLabel: "All properties",
            tokenLimitShowMore: "Show more",
            tokenLimitShowFewer: "Show fewer",
            clearFiltersText: "Clear filters",
            removeTokenButtonAriaLabel: token =>
              `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
            enteredTextLabel: text => `Use: "${text}"`
          }}
          countText={getMatchesCountText(filteredItemsCount)}
          expandToViewport={true}
        />
        <div style={{marginTop: '10px'}}>
            {
              filterOptions.map((item: any, index: number) => {
                
                return(
                  <div className="item_filtered" key={index}>
                    <div className="item_filtered_content" ><strong>{`${item?.tokens[0]?.propertyKey} ${item?.tokens[0]?.operator}`}</strong>{` ${item?.tokens[0]?.value}`}</div>
                    <div className="item_filtered_close" onClick={() => _handleDeleteFilter(item?.tokens[0]?.value)}>
                      <Icons.Trash/>
                    </div>
                  </div>
                  
                )
              })
            }
        </div>
        </div>
        
      }
      visibleColumns={['jobName', ...preferences.visibleContent]}
    />
  );
};

import { DateTime } from "luxon";

function Time(input) {
  var mins = Math.floor(input / 60);
  var secs = Math.floor(input - mins * 60).toLocaleString("en-GB", {
    maximumFractionDigits: 1,
  });

  return `${mins}`.padStart(2, "0") + ":" + `${secs}`.padStart(2, "0");
}

function convertToNumber(timeString) {
  // Split the string into hours and minutes
  const [hours, minutes] = timeString.split(":").map(Number);
  
  // Convert hours to minutes and add minutes
  const totalMinutes = hours * 60 + minutes;
  
  // Convert to a number format (e.g., 500.45)
  const fractionalMinutes = minutes / 60;
  const result = hours * 60 + fractionalMinutes * 60;
  
  return result.toFixed(2); // Limit to 2 decimal places
}

function Percentage(input) {
  return input.toLocaleString("en-GB", {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
}

function Number(input) {
  return input.toLocaleString("en-GB", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function Timestamp(input) {
  const dt = DateTime.fromMillis(input, {zone: "utc" });
  return dt.toISO().slice(0, 19).replace("T", " ");
}

export const Formatter = { Percentage, Number, Time, Timestamp, convertToNumber };

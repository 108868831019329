import { injectable } from "inversify";
import AIRepository from "../../repositories/AIRepository";

interface props {
    aiRepository: AIRepository;
}

@injectable()
export class GetAIMomentsUseCase {
    _aiRepository: AIRepository;

    constructor(_: props) {
        this._aiRepository = _.aiRepository;
    }

    public call = async (keywords: string[], page: number, type: string, customer: string, lob: string): Promise<any> => {
        return await this._aiRepository.getAIMoments(keywords, page, type, customer, lob);
    }
}

export const GetAIMomentsUseCaseName = "GetAIMomentsUseCaseName";
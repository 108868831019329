import { injectable } from "inversify";
import Testing from "../../../ui/utils/Testing";
import AIRepository from "../../../domain/repositories/AIRepository";

@injectable()
class AIRepositoryFake implements AIRepository {
    getAISumaries = async (): Promise<any> => {
        await Testing.sleeper(1000);
        return [];
    }
    getAISumaryByRecord = async (record: string): Promise<any> =>  {
        await Testing.sleeper(1000);
        return [];
    }
    getAIEmbedURL = async (): Promise<any> =>  {
        await Testing.sleeper(1000);
        return [];
    }
    
    searchAISummaries = async (query: any): Promise<any> =>  {
        await Testing.sleeper(1000);
        return [];
    }

    searchAISummariesLanguages = async (): Promise<any> => {
        await Testing.sleeper(1000);
        return [];
    }
    searchAISummariesEntities = async (): Promise<any> => {
        await Testing.sleeper(1000);
        return [];
    }
    getAIAgents = async (): Promise<any> => {
        await Testing.sleeper(1000);
        return [];
    }
    getAICustomers = async (): Promise<any> => {
        await Testing.sleeper(1000);
        return [];
    }
    getAIPerson = async (query: any, type: string): Promise<any> => {
        await Testing.sleeper(1000);
        return [];
    }
    updateAISumaryByRecord = async (record: string, content: any): Promise<any> => {
        await Testing.sleeper(1000);
        return [];
    }
    
    getAIMoments  = async (): Promise<any> => {
        await Testing.sleeper(1000);
        return [];
    }
    
    UpdateAIMomentById = async(description: string, keyword_id: number): Promise<any> => {
        await Testing.sleeper(1000);
        return [];
    }
    CreateAIMoment = async(customer: string, lob: string, type: string, description: string, weight: number): Promise<any> => {
        await Testing.sleeper(1000);
        return [];
    }
    DeleteAIMoment = async(keyword_id: number): Promise<any> => {
        await Testing.sleeper(1000);
        return [];
    }
}
export default AIRepositoryFake;